import React, { useMemo } from "react";
import { useDropzone } from "react-dropzone";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  borderWidth: 3,
  borderRadius: 5,
  borderColor: "#E5E5E5",
  borderStyle: "dashed",
  backgroundColor: "#ffffff",
  color: "#2a3134",
  height: "100%",
  outline: "none",
  // transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#4bc88c",
  color: "#4bc88c",
};

const acceptStyle = {
  borderColor: "#4bc88c",
};

const rejectStyle = {
  borderColor: "#4bc88c",
};

export function MaterialActionUpload(props) {
  const onDrop = (file) => {
    if (file.length > 0) {
      const materialFile = file[0];
      props.addMaterialFiles(materialFile, props.sequence_id, props.ueId);
      props.addMaterialTmpRow(materialFile);
    }
  };

  const onCancel = () => {};

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    onFileDialogCancel: onCancel,
    multiple: false,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept],
  );

  return (
    <div className="uploadbox">
      <div className="title">Datei hochladen</div>
      <div className="box">
        <div className="innerBox" {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <p>Datei hochladen</p>
        </div>
      </div>
    </div>
  );
}
