import React, { useEffect } from "react";
import { MaterialItem } from "./MaterialItem";
import { MaterialActionLink } from "./MaterialActionLink";
import { MaterialActionUpload } from "./MaterialActionUpload";
import ScreenNote from "../../../common/components/ScreenNotes";
import { selectSettings } from "../../../features/settings/settings-slice";
import { selectCurrentSchoolyear } from "../../../features/current-schoolyear/current-schoolyear-slice";
import { useAppSelector, useAppDispatch } from "../../hooks";
import { api } from "../../../services/api";

import IconPfeil from "../../../assets/images/svg/pfeil-tabelle.svg";

const defaultMaterialSetting = {
  sequence: {
    art: "desc",
    name: "desc",
    date: "desc",
    last: "date",
  },
  ue: {
    art: "desc",
    name: "desc",
    date: "desc",
    last: "date",
  },
};

function ToggleMaterialsWithUeDialog({
  model,
  toggleGetAllMaterialsToSequence,
}) {
  const msg = model.showMaterialWithUes ? "ausblenden" : "anzeigen";
  return (
    <div className="row add_ue_items">
      <a onClick={toggleGetAllMaterialsToSequence}>
        Material aus Unterrichtseinheiten
        <span className="toggle">{msg}</span>
      </a>
    </div>
  );
}

/**
 * @param type
 * @param order
 * @param context
 * @param props
 */
const updateSortSettings = (type, schoolyear, order, context, settings) => {
  const defaultSettings =
    !settings.settings.material_list || !settings.settings.material_list.ue
      ? defaultMaterialSetting
      : settings.settings.material_list;

  const upatedSettings =
    context === "ue"
      ? { ...defaultSettings, ue: { last: type, [type]: order } }
      : { ...defaultSettings, sequence: { last: type, [type]: order } };

  const newSettings = {
    ...settings,
    schoolyear,
    settings: {
      ...settings.settings,
      material_list: {
        ...defaultSettings,
        ...upatedSettings,
      },
    },
  };

  return newSettings;
};

/**
 * sort via settings options
 * @param materialData
 * @param settings
 * @returns {this}
 */
const sortMaterialData = (materialData, settings) => {
  switch (settings.last) {
    case "art":
      return [...materialData].sort(
        (a, b) =>
          (a.title.substring(a.title.lastIndexOf(".")) <
          b.title.substring(b.title.lastIndexOf("."))
            ? -1
            : 1) * (settings.art === "asc" ? 1 : -1),
      );
    case "name":
      return [...materialData].sort(
        (a, b) =>
          (a.title.toUpperCase() < b.title.toUpperCase() ? -1 : 1) *
          (settings.name === "asc" ? 1 : -1),
      );
    case "date":
      return [...materialData].sort(
        (a, b) =>
          (a.dateTime < b.dateTime ? -1 : 1) *
          (settings.date === "asc" ? 1 : -1),
      );
    default:
      return materialData;
  }
};

function EmptyStatePlaceholder() {
  const lines = [...Array(10).keys()];
  return (
    <div className="item-list emptyState">
      <div className="filter-head">
        <div className="row">
          <span className="icon">
            <span className="placeholder headIcon" />
          </span>
          <span className="name">
            <span className="placeholder" />
          </span>
          <span className="date">
            <span className="placeholder" />
          </span>
          <span className="process" />
        </div>
      </div>

      {lines.map((value) => (
        <div className="items" key={value}>
          <div className="row flex items-center">
            <span className="icon flex items-center">
              <span className="placeholder square" />
            </span>
            <span className="name flex items-center">
              <span className="placeholder" />
            </span>
            <span className="date flex items-center">
              <span className="placeholder" />
            </span>
            <span className="process flex items-center">
              <span className="placeholder square small" />
              &nbsp;
              <span className="placeholder medium" />
              <span className="placeholder thin" />
            </span>
          </div>
        </div>
      ))}
    </div>
  );
}

const toggleSort = (sort) => (sort === "asc" ? "desc" : "asc");

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function Materials(props) {
  const { actions, thunks, ueId, modelSelector, isVisible } = props;

  // select state
  const schoolyear = useAppSelector(selectCurrentSchoolyear);
  const ueList = useAppSelector((state) => state.sequences.timeslots);
  const sequenceObjectId = useAppSelector((state) => state.sequences._id);
  const allSettings = useAppSelector(selectSettings);
  const model = useAppSelector(modelSelector);

  // dispatch stuff
  const dispatch = useAppDispatch();

  const [updateSettings] = api.useUpdateSettingsMutation();

  const addMaterialTmpRow = (data) => {
    dispatch(actions.setTempMaterialRow(data));
  };

  const linkPreviewDispatch = (url, type = "linkbox", materialId = false) => {
    dispatch(thunks.fetchLinkPreview(url, type, materialId));
  };

  const abortUpload = () => {
    dispatch(
      thunks.abortUploadProcess(
        model.uploadMaterialRequestId,
        model.newMaterialItemId,
        sequenceObjectId,
      ),
    );
  };

  const closeSuccessNote = () => {
    dispatch(actions.resetNewMaterialAddedName());
  };

  const deleteMaterialItemDispatch = (materialId, sequenceId, ueId_) => {
    dispatch(thunks.deleteMaterialItem(materialId, sequenceId, ueId_));
  };

  const undoDeleteMaterialItemDispatch = (materialId, sequenceId, ueId_) => {
    dispatch(thunks.undoDeleteMaterialItem(materialId, sequenceId, ueId_));
  };

  // effects
  const showUeMaterials = !ueId;

  // NOTE: the isVisible prop here is just a workaround to refetch the materials
  // when they have been added or deleted in other places.
  // A cleaner way to handle that would be to have both components use the same material
  // list in the state (but leave the other properties separate)
  useEffect(() => {
    dispatch(thunks.fetchMaterialData(sequenceObjectId, ueId, showUeMaterials));
  }, [dispatch, thunks, sequenceObjectId, ueId, showUeMaterials, isVisible]);

  // other stuff
  const settings =
    allSettings.settings &&
    allSettings.settings.material_list &&
    allSettings.settings.material_list.ue
      ? allSettings.settings.material_list
      : defaultMaterialSetting;

  const sortedMaterialData = sortMaterialData(
    model.material,
    settings.sequence,
  );
  const sortedUeMaterialData = sortMaterialData(model.ueMaterial, settings.ue);

  const setSortSettings = (type, order, context) => {
    const newSettings = updateSortSettings(
      type,
      schoolyear,
      order,
      context,
      allSettings,
    );
    updateSettings(newSettings);
    dispatch(actions.resetNewMaterialItemId());
  };

  // nächste sortierung festlegen
  const nextOrderDate = toggleSort(settings.sequence.date);
  const nextOrderName = toggleSort(settings.sequence.name);
  const nextOrderArt = toggleSort(settings.sequence.art);
  const ueNextOrderDate = toggleSort(settings.ue.date);
  const ueNextOrderName = toggleSort(settings.ue.name);
  const ueNextOrderArt = toggleSort(settings.ue.art);

  // css for toggle asc/desc order item
  const activeArtOrderCss =
    settings.sequence.last === "art" ? `active ${settings.sequence.art}` : "";
  const activeNameOrderCss =
    settings.sequence.last === "name" ? `active ${settings.sequence.name}` : "";
  const activeDateOrderCss =
    settings.sequence.last === "date" ? `active ${settings.sequence.date}` : "";

  const ueActiveArtOrderCss =
    settings.ue.last === "art" ? `active  ${settings.ue.art}` : "";
  const ueActiveNameOrderCss =
    settings.ue.last === "name" ? `active ${settings.ue.name}` : "";
  const ueActiveDateOrderCss =
    settings.ue.last === "date" ? `active ${settings.ue.date}` : "";

  /**
   * show success note
   * @returns {*}
   */
  const getSuccessNote = (name, fileType) => {
    const msgType =
      fileType === "link"
        ? "Dein Link wurde erfolgreich gespeichert"
        : "wurde erfolgreich hochgeladen!";

    const msgUser =
      fileType === "link" ? msgType : `<strong>${name}</strong> ${msgType}`;

    return (
      <ScreenNote
        type="success"
        message={msgUser}
        closeCallback={closeSuccessNote}
        onAnimationEndClose={() => closeSuccessNote}
      />
    );
  };

  const closeErrorNote = () => {
    dispatch(actions.hideMaterialUploadError(model.newMaterialUploadFileName));
  };

  /**
   * show error note
   * @returns {*}
   */
  const getErrorNote = (fileName) => {
    const msg = `${fileName} konnte nicht hochgeladen werden`;
    return (
      <ScreenNote type="error" message={msg} closeCallback={closeErrorNote} />
    );
  };

  const toggleGetAllMaterialsToSequence = () => {
    const newSettings = {
      ...allSettings,
      schoolyear,
      settings: {
        ...allSettings.settings,
        material_list: {
          ...(allSettings.settings.material_list || {}),
          showWithUes: !model.showMaterialWithUes,
        },
      },
    };

    dispatch(actions.toggleShowMaterialsWithUes());
    updateSettings(newSettings);
  };

  const showMaterialUploadSuccess =
    model.newMaterialUploadFileName !== ""
      ? getSuccessNote(
          model.newMaterialUploadFileName,
          model.newMaterialUploadFileType,
        )
      : null;

  const showMaterialUploadError = model.newMaterialUploadError
    ? getErrorNote(model.newMaterialUploadErrorFile)
    : null;

  return (
    <div className="tw-pf material-upload-wrapper">
      {showMaterialUploadSuccess}
      {showMaterialUploadError}

      <div className="item-list">
        {sortedMaterialData.length > 0 ||
        sortedUeMaterialData.length > 0 ||
        model.emptyStateIsHidden ? (
          <div className="sequenceWrapper">
            {sortedMaterialData.length > 0 && (
              <div className="filter-head">
                <div className="row">
                  <span
                    onClick={() =>
                      setSortSettings("art", nextOrderArt, "sequence")
                    }
                    className={`icon ${activeArtOrderCss}`}
                  >
                    Art
                    <IconPfeil className={activeArtOrderCss} />
                  </span>
                  <span
                    onClick={() =>
                      setSortSettings("name", nextOrderName, "sequence")
                    }
                    className={`name ${activeNameOrderCss}`}
                  >
                    Name <IconPfeil className={activeNameOrderCss} />
                  </span>
                  <span
                    onClick={() =>
                      setSortSettings("date", nextOrderDate, "sequence")
                    }
                    className={`date ${activeDateOrderCss}`}
                  >
                    hinzugefügt <IconPfeil className={activeDateOrderCss} />
                  </span>
                  <span className="process" />
                </div>
              </div>
            )}

            <div className="items">
              {model.newMaterialTempRow && (
                <MaterialItem
                  key={model.newMaterialTempRow.id}
                  name={model.newMaterialTempRow.title}
                  date={model.newMaterialTempRow.dateTime}
                  type={model.newMaterialTempRow.type}
                  link={model.newMaterialTempRow.link}
                  ueId={ueId}
                  materialId={model.newMaterialTempRow.id}
                  sequenceId={sequenceObjectId}
                  isUploadPlaceholder
                  materialFileDownload={(materialId, fileName) => {
                    dispatch(thunks.materialFileDownload(materialId, fileName));
                  }}
                  linkPreview={linkPreviewDispatch}
                  previewData={
                    model.newMaterialTempRow.previewData
                      ? model.newMaterialTempRow.previewData
                      : null
                  }
                  newMaterialItemId={model.newMaterialItemId}
                  resetNewMaterialItemId={() => {
                    dispatch(actions.resetNewMaterialItemId());
                  }}
                  deleteMaterialItem={deleteMaterialItemDispatch}
                  uploadMaterialProgressPercent={
                    model.uploadMaterialProgressPercent
                  }
                  abortUploadProcess={abortUpload}
                />
              )}

              {sortedMaterialData.map((item) => (
                <MaterialItem
                  key={item.id}
                  name={item.title}
                  date={item.dateTime}
                  type={item.type}
                  link={item.link}
                  ueId={item.ues && item.ues.length > 0 ? item.ues[0] : ueId}
                  materialId={item.id}
                  sequenceId={sequenceObjectId}
                  materialFileDownload={(materialId, fileName) => {
                    dispatch(thunks.materialFileDownload(materialId, fileName));
                  }}
                  linkPreview={linkPreviewDispatch}
                  previewData={item.metaData ? item.metaData : null}
                  newMaterialItemId={model.newMaterialItemId}
                  resetNewMaterialItemId={() => {
                    dispatch(actions.resetNewMaterialItemId());
                  }}
                  deleteMaterialItem={deleteMaterialItemDispatch}
                  removeDeletedMaterial={(materialId) => {
                    dispatch(actions.removeDeletedMaterial(materialId));
                  }}
                  undoDeleteMaterialItem={undoDeleteMaterialItemDispatch}
                />
              ))}
            </div>
          </div>
        ) : (
          <EmptyStatePlaceholder />
        )}

        {!ueId && sortedUeMaterialData.length > 0 && (
          <ToggleMaterialsWithUeDialog
            model={model}
            toggleGetAllMaterialsToSequence={toggleGetAllMaterialsToSequence}
          />
        )}

        {sortedUeMaterialData.length > 0 && model.showMaterialWithUes && (
          <div className="ueWrapper">
            <div className="filter-head ueHead">
              <div className="row">
                <span
                  onClick={() => setSortSettings("art", ueNextOrderArt, "ue")}
                  className={`icon ${ueActiveArtOrderCss}`}
                >
                  Art
                  <IconPfeil className={ueActiveArtOrderCss} />
                </span>
                <span
                  onClick={() => setSortSettings("name", ueNextOrderName, "ue")}
                  className={`name ${ueActiveNameOrderCss}`}
                >
                  Name <IconPfeil className={ueActiveNameOrderCss} />
                </span>
                <span
                  onClick={() => setSortSettings("date", ueNextOrderDate, "ue")}
                  className={`date ${ueActiveDateOrderCss}`}
                >
                  verwendet <IconPfeil className={ueActiveDateOrderCss} />
                </span>
                <span className="process" />
              </div>
            </div>
            <div className="items">
              {sortedUeMaterialData.map((item) => {
                const itemUeId =
                  item.ues && item.ues.length > 0 ? item.ues[0] : ueId;

                const ueObj = ueList.find(
                  (ue) => ue.ueId && ue.ueId === itemUeId,
                );

                return (
                  <MaterialItem
                    key={item.id}
                    name={item.title}
                    type={item.type}
                    link={item.link}
                    ueId={itemUeId}
                    date={ueObj?.date}
                    materialId={item.id}
                    sequenceId={sequenceObjectId}
                    materialFileDownload={(materialId, fileName) => {
                      dispatch(
                        thunks.materialFileDownload(materialId, fileName),
                      );
                    }}
                    linkPreview={linkPreviewDispatch}
                    previewData={item.metaData ? item.metaData : null}
                    newMaterialItemId={model.newMaterialItemId}
                    resetNewMaterialItemId={() => {
                      dispatch(actions.resetNewMaterialItemId());
                    }}
                    deleteMaterialItem={deleteMaterialItemDispatch}
                    removeDeletedMaterial={(materialId) => {
                      dispatch(actions.removeDeletedMaterial(materialId));
                    }}
                    undoDeleteMaterialItem={undoDeleteMaterialItemDispatch}
                  />
                );
              })}
            </div>
          </div>
        )}
      </div>

      <div className="actions">
        <MaterialActionUpload
          addMaterialFiles={(file, sequenceId, ueId_) =>
            dispatch(thunks.addMaterialFiles(file, sequenceId, ueId_))
          }
          addMaterialTmpRow={addMaterialTmpRow}
          sequence_id={sequenceObjectId}
          ueId={ueId}
        />

        <MaterialActionLink
          ueId={ueId}
          addMaterialLink={(material, sequenceId, ueId_) =>
            dispatch(thunks.addMaterialLink(material, sequenceId, ueId_))
          }
          materialLinkEnabled={model.materialLinkEnabled}
          linkPreview={linkPreviewDispatch}
          linkPreviewClear={() => dispatch(actions.linkPreviewClear())}
          sequence_id={sequenceObjectId}
          materialLinkPreview={model.materialLinkPreview}
          hasMaterialLinkValueError={model.hasMaterialLinkValueError}
          materialLinkValue={model.materialLinkValue}
          updateLinkValue={(value) => dispatch(actions.updateLinkValue(value))}
          materialLinkUrlError={(value) =>
            dispatch(actions.materialLinkUrlError(value))
          }
        />
      </div>
    </div>
  );
}

export default Materials;
