'use strict';

exports.__esModule = true;

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var findDefaultTab = function findDefaultTab(children) {
  var firstLink = void 0;
  var firstDefaultLink = void 0;

  var traverse = function traverse(child) {
    if (!child || !child.props || firstDefaultLink) {
      return;
    }

    if (child.type.displayName === 'TabLink') {
      firstLink = firstLink || child.props.to;
      firstDefaultLink = firstDefaultLink || child.props.default && child.props.to;
    }

    _react2.default.Children.forEach(child.props.children, traverse);
  };

  _react2.default.Children.forEach(children, traverse);

  return firstDefaultLink || firstLink;
};

var Tabs = function (_Component) {
  _inherits(Tabs, _Component);

  function Tabs() {
    var _temp, _this, _ret;

    _classCallCheck(this, Tabs);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, _Component.call.apply(_Component, [this].concat(args))), _this), _this.state = {
      selectedTab: _this.props.selectedTab || findDefaultTab(_this.props.children)
    }, _this.handleSelect = function (tab) {
      _this.setState({
        selectedTab: tab
      });
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  Tabs.prototype.componentDidMount = function componentDidMount() {
    this.props.onChange(this.state.selectedTab, this.props.name);
  };

  Tabs.prototype.componentDidUpdate = function componentDidUpdate(prevProps, prevState) {
    if (this.state.selectedTab !== prevState.selectedTab) {
      this.props.onChange(this.state.selectedTab, this.props.name);
    }
  };

  Tabs.prototype.componentWillReceiveProps = function componentWillReceiveProps(newProps) {
    if (this.props.selectedTab !== newProps.selectedTab) {
      this.setState({
        selectedTab: newProps.selectedTab
      });
    }
  };

  Tabs.prototype.transformChildren = function transformChildren(children, _ref) {
    var _this2 = this;

    var handleSelect = _ref.handleSelect,
        selectedTab = _ref.selectedTab,
        activeLinkStyle = _ref.activeLinkStyle,
        visibleTabStyle = _ref.visibleTabStyle,
        disableInlineStyles = _ref.disableInlineStyles,
        name = _ref.name,
        tabComponent = _ref.tabComponent;

    if ((typeof children === 'undefined' ? 'undefined' : _typeof(children)) !== 'object') {
      return children;
    }

    return _react2.default.Children.map(children, function (child) {
      if (!child) {
        return child;
      }
      if (child.type.displayName === 'TabLink') {
        return _react2.default.cloneElement(child, {
          handleSelect: handleSelect,
          isActive: child.props.to === selectedTab,
          activeStyle: activeLinkStyle,
          disableInlineStyles: disableInlineStyles,
          namespace: name,
          component: child.props.component || tabComponent
        });
      }

      if (child.type.displayName === 'TabContent') {
        return _react2.default.cloneElement(child, {
          isVisible: child.props.for === selectedTab,
          visibleStyle: visibleTabStyle,
          disableInlineStyles: disableInlineStyles,
          renderActiveTabContentOnly: _this2.props.renderActiveTabContentOnly
        });
      }

      return _react2.default.cloneElement(child, {}, _this2.transformChildren(child.props && child.props.children, {
        handleSelect: handleSelect,
        selectedTab: selectedTab,
        activeLinkStyle: activeLinkStyle,
        visibleTabStyle: visibleTabStyle,
        disableInlineStyles: disableInlineStyles,
        name: name,
        tabComponent: tabComponent
      }));
    });
  };

  Tabs.prototype.render = function render() {
    var _props = this.props,
        handleSelectProp = _props.handleSelect,
        selectedTabProp = _props.selectedTab,
        activeLinkStyle = _props.activeLinkStyle,
        visibleTabStyle = _props.visibleTabStyle,
        disableInlineStyles = _props.disableInlineStyles,
        name = _props.name,
        renderActiveTabContentOnly = _props.renderActiveTabContentOnly,
        tabComponent = _props.tabComponent,
        divProps = _objectWithoutProperties(_props, ['handleSelect', 'selectedTab', 'activeLinkStyle', 'visibleTabStyle', 'disableInlineStyles', 'name', 'renderActiveTabContentOnly', 'tabComponent']);

    var handleSelect = handleSelectProp || this.handleSelect;

    var children = this.transformChildren(this.props.children, {
      handleSelect: handleSelect,
      selectedTab: this.state.selectedTab,
      activeLinkStyle: activeLinkStyle,
      visibleTabStyle: visibleTabStyle,
      disableInlineStyles: disableInlineStyles,
      name: name,
      tabComponent: tabComponent
    });

    return _react2.default.createElement(
      'div',
      divProps,
      children
    );
  };

  return Tabs;
}(_react.Component);

Tabs.propTypes = process.env.NODE_ENV !== "production" ? {
  name: _propTypes2.default.string,
  tabComponent: _propTypes2.default.string,
  children: _propTypes2.default.node,
  onChange: _propTypes2.default.func,
  handleSelect: _propTypes2.default.func,
  selectedTab: _propTypes2.default.string,
  activeLinkStyle: _propTypes2.default.object,
  visibleTabStyle: _propTypes2.default.object,
  disableInlineStyles: _propTypes2.default.bool,
  renderActiveTabContentOnly: _propTypes2.default.bool
} : {};

Tabs.defaultProps = {
  onChange: function onChange() {}
};

exports.default = Tabs;
module.exports = exports['default'];