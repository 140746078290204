import { createSlice } from "@reduxjs/toolkit";
import Auth from "../../common/services/Auth";
import * as URLS from "../../common/constants/ApiRoutes";
import uploadTracker from "../../common/utils/upload-tracker";

// INITIAL STATE
const initialState = {
  material: [],
  ueMaterial: [],
  showMaterialWithUes: true,
  materialLinkPreview: null,
  materialLinkValue: "",
  hasMaterialLinkValueError: false,
  newMaterialUploadError: false,
  newMaterialUploadErrorFile: "",
  newMaterialItemId: null,
  newMaterialUploadFileName: "",
  newMaterialUploadFileType: "",
  materialLinkEnabled: true,
  uploadMaterialProgressPercent: 0,
  uploadMaterialRequestId: null,
  newMaterialTempRow: false,
  emptyStateIsHidden: false,
  deleteMaterialError: false,
};

// REDUCERS AND ACTION CREATORS

const reducers = {
  uploadProgress: {
    reducer: (state, action) => ({
      ...state,
      uploadMaterialProgressPercent: action.payload.percentage,
      uploadMaterialRequestId: action.payload.requestId,
      newMaterialItemId: action.payload.materialId,
    }),
    prepare: (status, newMaterialId) => ({
      payload: {
        state: status.state,
        percentage: status.percentage,
        requestId: status.requestId,
        materialId: newMaterialId,
      },
    }),
  },
  addMaterialData: (state, action) => {
    const { material, newMaterialId, materialName } = action.payload;
    return {
      ...state,
      material: [...state.material, material],
      newMaterialItemId: newMaterialId,
      hasMaterialLinkValueError: false,
      newMaterialUploadFileName: materialName,
      newMaterialUploadFileType: "file",
      uploadMaterialProgressPercent: 0,
      newMaterialTempRow: false,
      emptyStateIsHidden: false,
    };
  },
  addMaterialDataError: {
    reducer: (state, action) => ({
      ...state,
      newMaterialUploadError: true,
      newMaterialUploadErrorFile: action.payload.materialName,
      newMaterialTempRow: false,
      emptyStateIsHidden: false,
    }),
    prepare: (materialName, message) => ({
      payload: {
        materialName,
        message,
      },
    }),
  },
  hideMaterialUploadError: (state, action) => ({
    ...state,
    newMaterialUploadError: false,
    newMaterialUploadErrorFile: action.payload,
    newMaterialTempRow: false,
    emptyStateIsHidden: false,
  }),
  setTempMaterialRow: {
    reducer: (state, action) => {
      const data = action.payload;
      return {
        ...state,
        newMaterialTempRow: data,
        emptyStateIsHidden: true,
      };
    },
    prepare: (payload) => {
      const data = payload;
      return {
        payload: {
          mimeType: data.type,
          link: null,
          metaData: null,
          previewData: {},
          title: data.name,
          type: "file",
          dateTime: new Date().toISOString(),
          isUploadPlaceholder: true,
          id: 1, // tmpId only used for waiting upload
        },
      };
    },
  },
  removeTempMaterialRow: (state) => ({
    ...state,
    newMaterialTempRow: null,
    emptyStateIsHidden: true,
  }),

  materialItemDeleted: (state) => ({
    ...state,
    newMaterialTempRow: false,
  }),

  materialItemDeleteError: (state) => ({
    ...state,
    deleteMaterialError: true,
    newMaterialTempRow: false,
  }),

  linkPreview: (state, action) => {
    const { preview } = action.payload;
    return {
      ...state,
      materialLinkPreview: preview,
      hasMaterialLinkValueError: false,
    };
  },

  addLinkPreview: (state, action) => {
    const { preview, materialId } = action.payload;
    const newMaterial = state.material.map((item) => {
      if (item.id !== materialId) {
        return item;
      }
      return {
        ...item,
        previewData: preview,
      };
    });
    return {
      ...state,
      material: newMaterial,
      hasMaterialLinkValueError: false,
    };
  },

  linkPreviewClear: (state) => ({
    ...state,
    materialLinkPreview: null,
    hasMaterialLinkValueError: false,
    materialLinkValue: "",
  }),
  updateLinkValue: (state, action) => {
    const value = action.payload;
    return {
      ...state,
      materialLinkValue: value,
      materialLinkEnabled: true,
      materialLinkPreview: null,
      hasMaterialLinkValueError:
        value === "" ? false : state.hasMaterialLinkValueError,
    };
  },
  materialLinkUrlError: (state, action) => ({
    ...state,
    hasMaterialLinkValueError: action.payload,
    materialLinkPreview: null,
  }),
  resetNewMaterialItemId: (state) => ({ ...state, newMaterialItemId: null }),
  resetNewMaterialAddedName: (state) => ({
    ...state,
    newMaterialUploadFileName: "",
    newMaterialUploadErrorFile: "",
    newMaterialUploadFileType: "",
  }),
  removeDeletedMaterial: {
    reducer: (state, action) => {
      const materialId = action.payload;
      const isNotMaterialId = (item) => materialId !== item.id;
      return {
        ...state,
        material: state.material.filter(isNotMaterialId),
        ueMaterial: state.ueMaterial.filter(isNotMaterialId),
      };
    },
  },

  gotMaterialData: (state, action) => {
    const { all, material } = action.payload;
    const ueMaterials = !all
      ? []
      : material.filter((item) => item.ues && item.ues.length > 0);

    const materials = !all
      ? material
      : material.filter((item) => item.ues && item.ues.length === 0);
    return {
      ...state,
      // ...(!ueId ? { showMaterialWithUes: all } : {}),
      material: materials,
      ueMaterial: ueMaterials,
    };
  },

  toggleShowMaterialsWithUes: (state) => ({
    ...state,
    showMaterialWithUes: !state.showMaterialWithUes,
  }),
};

// THUNKS

const createThunks = (actions) => {
  const addMaterialFiles = (file, sequenceId, ueId = false) => {
    const fileData = {
      title: file.name,
      mimeType: file.type === "" ? null : file.type,
      metaData: {},
      filesize: file.size,
      ...(!ueId ? { sequenceId } : {}),
      ...(ueId ? { ueId } : {}),
    };

    return (dispatch) =>
      fetch(URLS.API_SEQUENCE_MATERIALS_CREATE_FILE_URL, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${Auth.getToken()}`,
        },
        body: JSON.stringify(fileData),
      }).then((response) => {
        if (response.ok) {
          response.json().then((result) => {
            // create material success, call was link and put file object
            const { material } = result.data;
            const newMaterialId = result.data.material.id;

            const onProgress = (status) => {
              if (!status.done) {
                dispatch(actions.uploadProgress(status, newMaterialId));
              }
            };
            uploadTracker
              .upload(
                result.data.uploadLink,
                { method: "put", body: file },
                onProgress,
              )
              .then((uploadResponse) => {
                if (uploadResponse.status === 200) {
                  dispatch(
                    actions.addMaterialData({
                      material,
                      newMaterialId,
                      materialName: file.name,
                    }),
                  );
                }
              });
          });
        } else {
          response.json().then((error) => {
            dispatch(actions.addMaterialDataError(file.name, error));
          });
        }
      });
  };

  const deleteMaterialItem = (materialId, sequenceId, ueId = false) => {
    const payLoad = !ueId
      ? { sequences: { remove: sequenceId } }
      : { ues: { remove: ueId } };
    return (dispatch) => {
      const endpointUrl = URLS.API_DELETE_SEQUENCE_MATERIAL + materialId;
      return fetch(endpointUrl, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${Auth.getToken()}`,
        },
        body: JSON.stringify(payLoad),
      }).then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            // check if unused
            if (data.data.unused) {
              // if unused, delete from bucket
              const deleteEndpoint = `${URLS.API_DELETE_MATERIAL}${materialId}`;
              return fetch(deleteEndpoint, {
                method: "delete",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `bearer ${Auth.getToken()}`,
                },
              }).then((deleteResponse) => {
                if (deleteResponse.status === 204) {
                  dispatch(actions.materialItemDeleted());
                } else {
                  deleteResponse.json().then(() => {
                    dispatch(actions.materialItemDeleteError());
                  });
                }
              });
            }
            dispatch(actions.materialItemDeleted());
            return Promise.resolve();
          });
        } else {
          response.json().then(() => {
            dispatch(actions.materialItemDeleteError());
          });
        }
      });
    };
  };

  const initFileDownload = (blob, fileName = "tmp-file") => {
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const materialFileDownload = (materialId, fileName) => () => {
    const endpointUrl = `${
      URLS.API_SEQUENCE_MATERIALS_GET_FILE_URL + materialId
    }/download`;
    return fetch(endpointUrl, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${Auth.getToken()}`,
      },
    }).then((response) => {
      if (response.ok) {
        return response
          .json()
          .then((json) => fetch(json.data.downloadLink))
          .then((fileResponse) => fileResponse.blob())
          .then((blob) => {
            initFileDownload(blob, fileName);
          });
      }
      return response.json().then((error) => {
        // @TODO: wie hier am besten mit dem Fehler umgehen?
        // eslint-disable-next-line no-console
        console.log(error);
      });
    });
  };

  const abortUploadProcess = (
    uploadMaterialRequestId,
    materialId,
    sequenceId,
  ) => {
    uploadTracker.abort(uploadMaterialRequestId);
    return (dispatch) => {
      dispatch(deleteMaterialItem(materialId, sequenceId));
    };
  };

  const fetchLinkPreview = (url, type, materialId) => {
    const apiUrl = `${
      URLS.API_SEQUENCE_MATERIALS_LINK_PREVIEW
    }/?url=${encodeURIComponent(url)}`;
    return (dispatch) =>
      fetch(apiUrl, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${Auth.getToken()}`,
        },
      }).then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            dispatch(
              type === "linkbox"
                ? actions.linkPreview({ preview: data.data, materialId })
                : actions.addLinkPreview({ preview: data.data, materialId }),
            );
          });
        } else {
          response.json().then(() => {
            // no error handling if preview failed
            dispatch(
              type === "linkbox"
                ? actions.linkPreview({ preview: {}, materialId })
                : actions.addLinkPreview({ preview: {}, materialId }),
            );
          });
        }
      });
  };

  const addMaterialLink = (material, sequenceId, ueId = false) => {
    const apiUrl = `${
      URLS.API_SEQUENCE_MATERIALS_LINK_PREVIEW
    }/?url=${encodeURIComponent(material.link)}`;
    return (dispatch) =>
      fetch(apiUrl, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${Auth.getToken()}`,
        },
      }).then((response) =>
        response.json().then((json) => {
          const materialLinkData = {
            ...material,
            ...(!ueId ? { sequenceId } : {}),
            ...(json.data ? { metaData: json.data } : {}),
            ...(ueId ? { ueId } : {}),
          };
          return fetch(URLS.API_SEQUENCE_MATERIALS_CREATE_LINK_URL, {
            method: "post",
            headers: {
              "Content-Type": "application/json",
              Authorization: `bearer ${Auth.getToken()}`,
            },
            body: JSON.stringify(materialLinkData),
          }).then((linkResponse) => {
            if (linkResponse.ok) {
              linkResponse.json().then((result) => {
                const { data } = result;
                dispatch(
                  actions.addMaterialData({
                    material: data.material,
                    newMaterialId: data.material.id,
                    materialName: "link", // use as placeholder
                  }),
                );
              });
            } else {
              linkResponse.json().then((error) => {
                // @TODO: was machen wenn ein fehler existiert
                // eslint-disable-next-line no-console
                console.log(`error create material: ${error}`);
              });
            }
          });
        }),
      );
  };

  const undoDeleteMaterialItem = (
    materialId,
    sequenceId = false,
    ueId = false,
  ) => {
    const payLoad = !ueId ? { sequenceId } : { ueId };
    return (dispatch) => {
      const endpointUrl = `${
        URLS.API_UNDO_DELETE_MATERIAL + materialId
      }/restore/`;
      return fetch(endpointUrl, {
        method: "put",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${Auth.getToken()}`,
        },
        body: JSON.stringify(payLoad),
      }).then((response) => {
        if (!response.ok) {
          response.json().then(() => {
            dispatch(actions.materialItemDeleteError());
          });
        }
      });
    };
  };

  const fetchMaterialData = (sequenceId, ueId = false, all = false) => {
    // if sequence id false - clear material state to empty list
    if (!sequenceId) {
      // TODO this is weird. maybe we can just avoid calling this thunk when there is no sequence id
      return actions.gotMaterialData({
        material: [],
        all: false,
      });
    }

    const queryParam = new URLSearchParams({
      ...(ueId ? { assignedToUe: ueId } : { assignedToSequence: sequenceId }),
      ...(!ueId && all ? { assignedToUeOfSequence: sequenceId } : {}),
    });

    return (dispatch) =>
      fetch(`${URLS.API_SEQUENCE_MATERIALS_URL}?${queryParam.toString()}`, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${Auth.getToken()}`,
        },
      }).then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            dispatch(
              actions.gotMaterialData({
                material: data.data.materials,
                all,
              }),
            );
          });
        } else {
          response.json().then(() => {
            dispatch(
              actions.gotMaterialData({
                all: false,
                material: [],
              }),
            );
          });
        }
      });
  };

  return {
    addMaterialFiles,
    addMaterialLink,
    abortUploadProcess,
    undoDeleteMaterialItem,
    deleteMaterialItem,
    materialFileDownload,
    fetchMaterialData,
    fetchLinkPreview,
  };
};

// EXPORTS

export const sequenceMaterialSlice = createSlice({
  name: "sequenceMaterial",
  initialState,
  reducers,
});

export const ueMaterialSlice = createSlice({
  name: "ueMaterial",
  initialState,
  reducers,
});

export const sequenceMaterialThunks = createThunks(
  sequenceMaterialSlice.actions,
);

export const ueMaterialThunks = createThunks(ueMaterialSlice.actions);
