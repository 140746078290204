import React, { useEffect, useState } from "react";
import { cutUrl, cutFilename } from "./Utils";
import { ImageWithDefaultBlock } from "./ImageWithDefaultBlock";
import { colors } from "../../../common/constants/colorScheme";

/**
 * if no image exists, show an div container as default
 * @param title
 * @returns {JSX.Element}
 */
function DefaultImagePreview(props) {
  const firstChar = props.previewTitle.charAt(0);
  return (
    <div className="defaultNoImage" style={{ backgroundColor: "#4bc88c" }}>
      <span className="text">{firstChar}</span>
    </div>
  );
}

/**
 * get an random value from freigeist color scheme
 */
const getPlaceholderColor = () =>
  colors[Math.floor(Math.random() * colors.length)];

/**
 * @param url
 * @returns {boolean|URL}
 */
const isValidUrl = (url) => {
  try {
    return new URL(url);
  } catch (e) {
    return false;
  }
};

/**
 * litle helper to check if have url an valid protocol
 * @param url
 * @returns {string|*}
 */
const validateLinkProtocol = (url) =>
  url.replace(/^(?!https?:\/\/)(.*)$/, "https://$1");

// @TODO: erstmal den Fehlertext statisch, mit Tobi klären was wir hier alles anzeigen wollen,
// zb. msg aus dem backend
const linkErrorMsg =
  "Der Link ist keine gültige URL und kann deswegen nicht gespeichert werden.";

export function MaterialActionLink(props) {
  const [showPreviewPlaceholder, setShowPreviewPlaceholder] = useState(false);
  const { materialLinkValue, materialLinkUrlError, linkPreview } = props;

  useEffect(() => {
    if (materialLinkValue !== "") {
      const delayDebounceFn = setTimeout(() => {
        const validatedUrlProtocol = validateLinkProtocol(
          materialLinkValue.trim(),
        );
        const validatedUrlValue = isValidUrl(validatedUrlProtocol);
        if (validatedUrlValue) {
          setShowPreviewPlaceholder(true);
          linkPreview(validatedUrlProtocol);
        } else {
          materialLinkUrlError(true);
        }
      }, 1000);
      return () => clearTimeout(delayDebounceFn);
    }
    return undefined;
  }, [materialLinkValue, linkPreview, materialLinkUrlError]);

  /**
   * @param e
   */
  const updateLinkValue = (value) => {
    props.updateLinkValue(value);
  };

  const clearInput = () => {
    setShowPreviewPlaceholder(false);
    props.linkPreviewClear();
  };

  const saveLink = () => {
    const validatedUrlProtocol = validateLinkProtocol(
      props.materialLinkValue.trim(),
    );
    const validatedUrlValue = isValidUrl(validatedUrlProtocol);
    if (validatedUrlValue) {
      const material = {
        link: validatedUrlValue,
        metaData:
          props.materialLinkPreview && props.materialLinkPreview.domain
            ? props.materialLinkPreview
            : { color: getPlaceholderColor() },
        title:
          props.materialLinkPreview && props.materialLinkPreview.domain
            ? props.materialLinkPreview.domain
            : validatedUrlValue,
      };
      props.addMaterialLink(material, props.sequence_id, props.ueId);
      props.linkPreviewClear();
      clearInput();
    } else {
      props.materialLinkUrlError(true);
    }
  };

  /**
   * fires form submit with enter
   * @param event
   */
  const saveOnEnter = (event) => {
    if (event.key === "Enter") {
      saveLink();
    }
  };

  const previewTitle =
    props.materialLinkPreview && props.materialLinkPreview.title
      ? props.materialLinkPreview.title
      : cutUrl(props.materialLinkValue);

  // default image
  const previewImage =
    props.materialLinkPreview && props.materialLinkPreview.imageUrl
      ? props.materialLinkPreview.imageUrl
      : false;

  const previewDomain =
    props.materialLinkPreview && props.materialLinkPreview.domain
      ? props.materialLinkPreview.domain
      : "";

  const previewFavicon =
    props.materialLinkPreview && props.materialLinkPreview.favicon
      ? props.materialLinkPreview.favicon
      : "";

  const btnDisabled = props.materialLinkValue === "";
  const linkPreviewUrlError = props.hasMaterialLinkValueError ? "error" : "";

  if (props.materialLinkPreview && showPreviewPlaceholder) {
    setShowPreviewPlaceholder(false);
  }

  if (props.hasMaterialLinkValueError && showPreviewPlaceholder) {
    setShowPreviewPlaceholder(false);
  }

  return (
    <div className="linkbox">
      <div className="title">Link speichern</div>
      <div className={`box ${linkPreviewUrlError}`}>
        <div>
          <input
            onChange={(e) => {
              updateLinkValue(e.target.value);
            }}
            value={props.materialLinkValue}
            className="inputfield"
            onKeyDown={(e) => saveOnEnter(e)}
            type="text"
          />
        </div>

        {props.hasMaterialLinkValueError && (
          <div className="errorMsg">{linkErrorMsg}</div>
        )}

        {showPreviewPlaceholder && (
          <div className="preview placeholder">
            <span className="icon">
              <span>
                <span className="img" />
                <span className="favicon" />
              </span>
            </span>
            <span className="title">
              <span className="og-title" />
              <span className="domain" />
            </span>
          </div>
        )}

        {props.materialLinkPreview && (
          <div className="preview">
            <span className="icon">
              {previewImage ? (
                <span>
                  <ImageWithDefaultBlock
                    className="defaultNoImage"
                    src={previewImage}
                    placeholderTxt=""
                  />
                  <span className="favicon">
                    <img src={previewFavicon} />
                  </span>
                </span>
              ) : (
                <DefaultImagePreview previewTitle={previewTitle} />
              )}
            </span>
            <span className="title">
              <span className="og-title">
                {cutFilename(previewTitle)}
                <br />
              </span>
              <span className="domain">{previewDomain}</span>
            </span>
          </div>
        )}
        <div className="mt-5 flex">
          <button
            className="btn btn-step"
            disabled={btnDisabled}
            onClick={() => {
              saveLink.bind(saveLink());
            }}
          >
            speichern
          </button>
          &nbsp; &nbsp; &nbsp;
          <button
            className="btn btn-dark"
            disabled={btnDisabled}
            onClick={() => {
              clearInput.bind(clearInput());
            }}
          >
            abbrechen
          </button>
        </div>
      </div>
    </div>
  );
}
