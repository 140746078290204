/* eslint-disable import/no-import-module-exports */
import { configureStore, createListenerMiddleware } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PURGE,
  REGISTER,
  PAUSE,
  PERSIST,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import * as Sentry from "@sentry/react";
import rootReducer from "./root-reducer";
import Auth from "../common/services/Auth";
import { api } from "../services/api";
import { sessionClipboard } from "../services/session-clipboard";

export type RootState = ReturnType<typeof rootReducer>;

// REDUX-PERSIST
const persistConfig = {
  key: "root-3",
  whitelist: [
    "current_schoolyear",
    "authentication",
    "registration",
    "payment",
    "active_view",
    "timetable",
    "addSubjectForm",
    "classes",
    "subjects",
    "taglist",
  ],
  storage,
  stateReconciler: autoMergeLevel2, // use this reconciler instead
};
const persistedReducer = persistReducer<RootState>(persistConfig, rootReducer);

// SENTRY
const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // only send type for actions since it's hard to filter out sensitive data
  actionTransformer: ({ type }) => ({ type }),
  // reduce state so that large and sensitive things are not sent
  stateTransformer: (state) => {
    const filteredState = Object.entries(state).filter(([name]) =>
      [
        "settings",
        "classes",
        "sequences",
        "active_view",
        "sequence_items",
      ].includes(name),
    );
    return Object.fromEntries(filteredState);
  },
});

// STORE
const store = configureStore({
  // Can create a root reducer separately and pass that in
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    const middleware = getDefaultMiddleware({
      // Pass in a custom `extra` argument to the thunk middleware
      // thunk: {
      //   extraArgument: {serviceLayer}
      // },
      // Customize the built-in serializability dev check
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      .prepend(createListenerMiddleware().middleware)
      .concat(api.middleware, sessionClipboard.middleware);

    // Conditionally add another middleware in dev
    return middleware;
  },
  // Turn off devtools in prod, or pass options in dev
  devTools: process.env.NODE_ENV !== "production",

  enhancers: (getDefaultEnhancers) =>
    getDefaultEnhancers({ autoBatch: { type: "tick" } }).concat(
      sentryReduxEnhancer,
    ),
});

// AppDispatch knows about thunks as well
export type AppDispatch = typeof store.dispatch;

// trigger logout when auth stuff is removed from localStorage in other tab
function handleStorageEvent({ key, oldValue, newValue }: StorageEvent) {
  const logOutInOtherTabHappened =
    key === "token" && oldValue !== null && newValue === null;

  if (logOutInOtherTabHappened) {
    store.dispatch({ type: "LOGOUT" });
  }
}
window.addEventListener("storage", handleStorageEvent);

if (module.hot) {
  // clean up window listener
  module.hot.dispose(() => {
    window.removeEventListener("storage", handleStorageEvent);
  });

  // replace reducer with new version
  module.hot.accept(() => {
    store.replaceReducer(persistedReducer);
  });
}

const persistor = persistStore(store, null, () => {});

const hasLoggedIn = Auth.isUserAuthenticated();
if (!hasLoggedIn) {
  persistor.purge();
}

// expose a Promise to wait on until state is rehydrated after page load
const storeRehydrationPromise: Promise<void> = new Promise((resolve) => {
  // resolve right away when state is bootstprapped
  if (persistor.getState().bootstrapped) {
    resolve();
    return;
  }

  // otherwise listen until it is bootstrapped
  const unsubscribeFn = persistor.subscribe(() => {
    if (persistor.getState().bootstrapped) {
      resolve();
      unsubscribeFn();
    }
  });
});

async function getAuthToken(): Promise<null | string> {
  // make sure we wait until redux persist has restored the state from localStorage
  // needed because react-router calls this loader before state is restored by redux-persist on page load
  await storeRehydrationPromise;
  const { authentication } = store.getState();
  return authentication?.token;
}

// enable listener behavior for the store
setupListeners(store.dispatch);

export default store;

export { getAuthToken, storeRehydrationPromise };
