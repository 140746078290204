import React from "react";

// import svgs as components
import IconWord from "../../../assets/images/svg/icon-word.svg";
import IconPdf from "../../../assets/images/svg/icon-pdf.svg";
import IconPpt from "../../../assets/images/svg/icon-ppt.svg";
import IconXls from "../../../assets/images/svg/icon-excel.svg";
import IconKeyNote from "../../../assets/images/svg/icon-keynote.svg";
import IconUnknown from "../../../assets/images/svg/icon-unbekannt.svg";
import IconImg from "../../../assets/images/svg/icon-bilddatei.svg";
import IconNumbers from "../../../assets/images/svg/icon-numbers.svg";
import IconPages from "../../../assets/images/svg/icon-pages.svg";
import IconVideo from "../../../assets/images/svg/icon-videodatei.svg";
/**
 * map filename or extension to display file icons
 * @param name
 * @returns {string}
 */
const fileExtensionRegex = /([^.]+)$/;
export function FileIcon({ name }: { name: string }) {
  const match = name.match(fileExtensionRegex);
  const extension = match ? match[0].toLowerCase() : "";
  switch (extension) {
    case "jpg":
    case "jpeg":
    case "gif":
    case "png":
    case "tiff":
    case "bmp":
    case "svg":
      return <IconImg />;

    case "mpg":
    case "mpeg":
    case "avi":
    case "wmv":
    case "mov":
    case "flv":
    case "mp4":
      return <IconVideo />;

    case "numbers":
      return <IconNumbers />;

    case "pages":
      return <IconPages />;

    case "pdf":
      return <IconPdf />;

    case "doc":
    case "docx":
      return <IconWord />;

    case "key":
      return <IconKeyNote />;

    case "ppt":
    case "pptx":
      return <IconPpt />;

    case "xls":
    case "xlsx":
      return <IconXls />;

    default:
      return <IconUnknown />;
  }
}
