import React, { useState } from "react";
import { cutFilename, formatMaterialDate } from "./Utils";
import { ImageWithDefaultBlock } from "./ImageWithDefaultBlock";
import { FileIcon } from "./FileIcon";
import IconDownload from "../../../assets/images/svg/icon-herunterladen.svg";
import IconLink from "../../../assets/images/svg/link-oeffnen.svg";
import IconDelete from "../../../assets/images/svg/delete.svg";

function ProcessDownload(props) {
  return (
    <span className="process_wrapper group">
      <a onClick={props.materialFileDownload} target="_blank">
        <span className="icon_process">
          <IconDownload className="group-hover:text-green" />
        </span>
        herunterladen
      </a>
    </span>
  );
}

function ProcessLink(props) {
  return (
    <span className="process_wrapper group">
      <a href={props.link} target="_blank" rel="noreferrer">
        <span className="icon_process link">
          <IconLink className="group-hover:text-green" />
        </span>
        Link öffnen
      </a>
    </span>
  );
}

function LinkIcon(props) {
  const firstChar =
    props.previewData && props.previewData.domain
      ? props.previewData.domain.charAt(0)
      : props.name.charAt(0);

  const previewDataImage =
    props.previewData && props.previewData.imageUrl
      ? props.previewData.imageUrl
      : false;

  const previewFavicon =
    props.previewData && props.previewData.favicon
      ? props.previewData.favicon
      : "";

  const previewDefaultColor =
    props.previewData && props.previewData.color
      ? props.previewData.color
      : "#4bc88c";

  return (
    <span>
      {previewDataImage ? (
        <span>
          <ImageWithDefaultBlock
            className="previewImg"
            src={previewDataImage}
            placeholderTxt={firstChar}
          />
          <span className="favicon">
            <img src={previewFavicon} />
          </span>
        </span>
      ) : (
        <div
          className="defaultNoImage"
          style={{ backgroundColor: previewDefaultColor }}
        >
          <span className="text">{firstChar}</span>
        </div>
      )}
    </span>
  );
}

function ProcessBarItemUploadProcess(props) {
  return (
    <span className="tempProcessbar">
      <div className="meter">
        <span className="meterHolder">
          <span className="progress" style={{ width: `${props.percent}%` }} />
        </span>
      </div>
    </span>
  );
}

function ProcessBarItemDeleteProcess(props) {
  const timerId = setTimeout(() => props.removeItem(), 60000);
  const cancelDelete = () => {
    clearTimeout(timerId);
    props.cancelDelete();
  };

  return (
    <span className="deleteDialog">
      <span className="deleteAccept">gelöscht</span>
      <a className="cancel" onClick={cancelDelete}>
        Wiederherstellen
      </a>
    </span>
  );
}

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function MaterialItem(props) {
  const [showDeleteItemDialog, setShowDeleteItemDialog] = useState(false);

  const deleteMaterial = () => {
    props.deleteMaterialItem(props.materialId, props.sequenceId);
  };

  /**
   * remove deleted marked item from list
   */
  const removeItem = () => {
    props.removeDeletedMaterial(props.materialId);
  };

  const prepareDelete = () => {
    setShowDeleteItemDialog(true);
    props.deleteMaterialItem(props.materialId, props.sequenceId, props.ueId);
  };

  const cancelDelete = () => {
    props.undoDeleteMaterialItem(
      props.materialId,
      props.sequenceId,
      props.ueId,
    );
    setShowDeleteItemDialog(false);
  };

  // load previewData for links if not exists
  if (props.type === "link" && !props.previewData) {
    props.linkPreview(props.link, "material", props.materialId);
  }

  const materialName =
    props.previewData && props.previewData.title
      ? props.previewData.title
      : props.name;

  const materialDomain =
    props.previewData && props.previewData.domain
      ? props.previewData.domain
      : "";

  const newItem = props.newMaterialItemId === props.materialId ? "added" : "";
  const cssClassUploadBar = props.isUploadPlaceholder ? "upload" : "";
  const cssPrepareDelete = showDeleteItemDialog
    ? "deletedialogOpen upload"
    : "";

  const cssLinkName = materialDomain === "" ? "oneRow" : "";

  return (
    <div
      className={`row ${newItem}`}
      onAnimationEnd={() => {
        props.resetNewMaterialItemId();
      }}
    >
      <span className={`icon ${cssClassUploadBar} ${cssPrepareDelete}`}>
        {props.type === "file" ? (
          <FileIcon name={props.name} />
        ) : (
          <LinkIcon name={props.name} previewData={props.previewData} />
        )}
      </span>

      {props.type === "file" ? (
        <span className={`name ${cssClassUploadBar} ${cssPrepareDelete}`}>
          {cutFilename(props.name)}
        </span>
      ) : (
        <span className={`name link ${cssPrepareDelete}`}>
          <span className={`linkName ${cssLinkName}`}>
            {cutFilename(materialName)}
          </span>
          {materialDomain !== "" && (
            <span className="domain">{materialDomain}</span>
          )}
        </span>
      )}

      {!props.isUploadPlaceholder ? (
        <span>
          <span className="deleteWrapper">
            {showDeleteItemDialog && (
              <ProcessBarItemDeleteProcess
                cancelDelete={cancelDelete}
                deleteMaterial={deleteMaterial}
                removeItem={removeItem}
                name={cutFilename(props.name)}
              />
            )}
            <span className={`date ${cssPrepareDelete}`}>
              {formatMaterialDate(props.date)}
            </span>
            <span className={`process ${cssPrepareDelete}`}>
              {props.type === "file" ? (
                <ProcessDownload
                  materialFileDownload={() => {
                    props.materialFileDownload(props.materialId, props.name);
                  }}
                />
              ) : (
                <ProcessLink link={props.link} />
              )}
            </span>
          </span>
        </span>
      ) : (
        <ProcessBarItemUploadProcess
          percent={props.uploadMaterialProgressPercent}
        />
      )}

      {!props.isUploadPlaceholder ? (
        <button
          type="button"
          className={`delete ${cssPrepareDelete}`}
          onClick={prepareDelete}
        >
          <IconDelete className="form-icon" />
        </button>
      ) : (
        <button
          type="button"
          className={`delete ${cssPrepareDelete}`}
          onClick={() => props.abortUploadProcess()}
        >
          <IconDelete className="form-icon" />
        </button>
      )}
    </div>
  );
}
