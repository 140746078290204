'use strict';

exports.__esModule = true;
exports.styles = undefined;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _class, _temp;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var styles = exports.styles = {
  hidden: {
    display: 'none'
  }
};

var TabContent = (_temp = _class = function (_Component) {
  _inherits(TabContent, _Component);

  function TabContent() {
    _classCallCheck(this, TabContent);

    return _possibleConstructorReturn(this, _Component.apply(this, arguments));
  }

  TabContent.prototype.canRenderChildren = function canRenderChildren() {
    return this.props.isVisible || !this.props.renderActiveTabContentOnly;
  };

  TabContent.prototype.render = function render() {
    var _classNames;

    var visibleStyle = this.props.visibleStyle || {};
    var displayStyle = this.props.isVisible ? visibleStyle : styles.hidden;
    var className = this.props.className || 'tab-content';
    var visibleClassName = this.props.visibleClassName || 'tab-content-visible';

    return _react2.default.createElement(
      'div',
      {
        id: 'tabpanel-' + this.props.for,
        role: 'tabpanel',
        'aria-labelledby': 'tab-' + this.props.for,
        className: (0, _classnames2.default)((_classNames = {}, _classNames[className] = true, _classNames[visibleClassName] = !!this.props.isVisible, _classNames)),
        style: !this.props.disableInlineStyles && _extends({}, this.props.style, displayStyle) || undefined
      },
      this.canRenderChildren() && this.props.children
    );
  };

  return TabContent;
}(_react.Component), _class.displayName = 'TabContent', _temp);


TabContent.propTypes = process.env.NODE_ENV !== "production" ? {
  children: _propTypes2.default.node,
  for: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.number]).isRequired, // eslint-disable-line react/no-unused-prop-types
  visibleStyle: _propTypes2.default.object,
  isVisible: _propTypes2.default.bool,
  renderActiveTabContentOnly: _propTypes2.default.bool,
  disableInlineStyles: _propTypes2.default.bool,
  className: _propTypes2.default.string,
  visibleClassName: _propTypes2.default.string,
  style: _propTypes2.default.object
} : {};

exports.default = TabContent;