import React from "react";
import { Link } from "react-router-dom";
import { Tabs, TabLink, TabContent } from "react-tabs-redux";
import { connect } from "react-redux";
import Articulation from "./Articulation/ArticulationComponent";
import FGEditor from "../../components/editor/FGEditor";
import Material from "../../components/material/Materials";
import SequenceMetaHeader from "./parts/HeaderSequenceMetaComponent";
import {
  sequenceMaterialSlice,
  sequenceMaterialThunks,
} from "../../../features/material/material-slice";
import { sequenceMaterialSelector } from "../../../features/sequence/reducer";
import { withRouter } from "../../../common/utils/routing";
import { PageTitle } from "../../../common/utils/hooks";
import * as ACTIONS from "../../../features/sequence/actions";
import { setActiveView } from "../../../features/active-view/active-view-slice";
import { selectSettings } from "../../../features/settings/settings-slice";
import { selectCurrentSchoolyear } from "../../../features/current-schoolyear/current-schoolyear-slice";

const mapStateToProps = (state) => ({
  sequenceNote: state.sequences.note,
  show404: state.sequences.show404,
  active_sequence: state.sequences.active_sequence_item,
  active_sequence_cluster: state.sequences.sequenceCluster,
  currentSchoolyear: selectCurrentSchoolyear(state),
  settings: selectSettings(state), // setting active tabs from database
  active_view: state.active_view,
});

const mapDispatchToProps = {
  updateTimeSlots: ACTIONS.updateTimeSlots,
  setActiveClass: setActiveView,
  setMaterialActiveTab: ACTIONS.setMaterialActiveTab,
  changeSequenceNote: ACTIONS.changeSequenceNote,
  setActiveSequenceView: ACTIONS.activeSequenceView,
  updateSequenceItemData: ACTIONS.updateSequenceItemData,
};

function NotFoundScreen() {
  return (
    <div className="tw-pf">
      <div className="flex flex-col items-center justify-center mt-40 text-gray-10">
        <div className="text-3xl font-bold">Diese Seite existiert nicht</div>
        <div className="text-xl mt-3.5 text-center">
          Vielleicht wurde die Sequenz, zu der der Link gehört,
          <br />
          gelöscht, oder im Link war ein Tippfehler.
        </div>
        <Link
          to="/zeitleiste"
          className="block mt-4.5 group px-1.5 bg-green text-sm text-gray-100 font-bold py-3 rounded hover:shadow-xl transition-shadow duration-200 focus:shadow-xl"
        >
          <span className="py-1.5 px-6 border-green group-focus:border-gray-100 border-dashed border-2 border-indigo-600 rounded-sm">
            Zur Zeitleiste
          </span>
        </Link>
      </div>
    </div>
  );
}

function setClassTab(props) {
  const currentClassId = props.active_view.classroom_id;
  const sequenceClassId = props.active_sequence_cluster.classroomId;

  if (sequenceClassId && currentClassId !== sequenceClassId) {
    props.setActiveClass(sequenceClassId);
  }
}

const PLACEHOLDER_MAIN_NOTES = "Trage hier Notizen zu dieser Sequenz ein";
class Sequences extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      unsaved_editor_value: false,
      force_note_save: false,
      setupSettings: false,
    };

    this.handleMainNoteUpdate = this.handleMainNoteUpdate.bind(this);
    this.updateSequenceItemsNote = this.updateSequenceItemsNote.bind(this);
    this.updateSequenceItemsTab = this.updateSequenceItemsTab.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.resetForceToSaveNote = this.resetForceToSaveNote.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick, false);
    // check AB weeks setting stuff
    let isAbWeekActive = false;
    let currentAbSettings = false;
    if (
      this.props.settings.settings.ab_weeks &&
      this.props.settings.settings.ab_weeks[this.props.currentSchoolyear] &&
      this.props.settings.settings.ab_weeks[this.props.currentSchoolyear]
        .active === 1
    ) {
      isAbWeekActive = true;
      currentAbSettings =
        this.props.settings.settings.ab_weeks[this.props.currentSchoolyear];
    }

    // init tabs
    let activeInitDate = 0;
    if (this.props.settings.settings.sequences) {
      this.props.settings.settings.sequences.forEach((elem) => {
        if (
          elem.seq_id &&
          String(elem.seq_id) === this.props.params.sequenceId
        ) {
          if (elem.active_day) {
            activeInitDate = elem.active_day;
          }
        }
      });
    }

    const queryParams = new URLSearchParams(this.props.location.search);
    // if ueId not exists in queryString - ueId is null
    // example request: sequenzen/{sequenceId}?ueId=626a97b0e42e43f9ae6faf5d
    const requestedUeId = queryParams.get("ueId");
    const requestedUeIdx = queryParams.get("ueIndex");

    // set the active sequnce view
    this.props.setActiveSequenceView(
      this.props.params.sequenceId,
      isAbWeekActive,
      currentAbSettings,
      activeInitDate,
      requestedUeId,
      requestedUeIdx,
    );

    setClassTab(this.props);
  }

  componentDidUpdate(prevProps, prevState) {
    const { props } = this;
    if (prevProps.active_sequence.tab !== props.active_sequence.tab) {
      this.setState({
        current_tab_settings: props.active_sequence.tab,
      });
    }

    if (!prevState.setupSettings) {
      const main_tab = props.active_sequence.tab
        ? props.active_sequence.tab
        : "schoolunites";

      this.setState({
        current_tab_settings: main_tab,
        setupSettings: true,
      });
      props.setMaterialActiveTab(main_tab);
    }

    setClassTab(props);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false);
  }

  handleClick(e) {
    if (this.node && this.node.contains(e.target)) {
      return;
    }
    const { unsaved_editor_value } = this.state;
    if (unsaved_editor_value) {
      this.setState({
        force_note_save: true,
      });
    }
  }

  handleMainNoteUpdate(input) {
    this.setState({
      unsaved_editor_value: true,
    });
    this.props.changeSequenceNote(input);
  }

  resetForceToSaveNote() {
    this.setState({
      force_note_save: false,
    });
  }

  /**
   * update articulation text from heade
   */
  updateSequenceItemsNote() {
    this.setState({
      unsaved_editor_value: false,
      force_note_save: false,
    });
    const newItem = {
      ...this.props.active_sequence,
      note: this.props.sequenceNote,
    };
    this.props.updateSequenceItemData(this.props.params.sequenceId, newItem);
  }

  /**
   * update tab
   */
  updateSequenceItemsTab(selectedTab) {
    this.setState({
      current_tab_settings: selectedTab,
    });
    const newItem = {
      ...this.props.active_sequence,
      tab: selectedTab,
    };
    this.props.updateSequenceItemData(this.props.params.sequenceId, newItem);
    this.props.setMaterialActiveTab(selectedTab);

    // temporaly hotfix: create timeslots with ueIds on first schoolunites tab click
    if (selectedTab === "schoolunites") {
      this.props.updateTimeSlots();
    }
  }

  render() {
    const sequenceKeys = Object.keys(this.props.active_sequence);
    const sequenceIsPlausible = ["id", "cluster", "title"].every((key) =>
      sequenceKeys.includes(key),
    );

    const border_notes_css_class =
      this.state.current_tab_settings === "notes" ? "no-left-top" : "";
    const { force_note_save } = this.state;
    const sequenceName = this.props.active_sequence.title ?? "Sequenzdetail";
    const title = `${sequenceName} | Freigeist`;
    return (
      <div id="sequence_wrapper">
        <PageTitle title={title} />
        {this.props.show404 ? (
          <NotFoundScreen />
        ) : (
          <div>
            <div id="component_nav">
              <div className="content-inner-wrapper">
                <SequenceMetaHeader />
              </div>
            </div>

            <div className="content-inner-wrapper">
              <Tabs
                name="sequenceMainTab"
                className="tab-main-wrapper"
                handleSelect={this.updateSequenceItemsTab}
                selectedTab={this.state.current_tab_settings}
              >
                <div className="tab-links">
                  <TabLink disabled={!sequenceIsPlausible} to="notes">
                    Notizen
                  </TabLink>
                  <TabLink disabled={!sequenceIsPlausible} to="material">
                    Material
                  </TabLink>
                  <TabLink disabled={!sequenceIsPlausible} to="schoolunites">
                    Unterrichtseinheiten
                  </TabLink>
                </div>

                <div className={`content ${border_notes_css_class}`}>
                  <TabContent for="notes">
                    <div
                      ref={(node) => {
                        this.node = node;
                      }}
                      className="tw-pf editor-wrapper note-editor-wrapper"
                    >
                      <FGEditor
                        print_id="seq_notes_editor"
                        key="seq_notes_editor"
                        placeholder={PLACEHOLDER_MAIN_NOTES}
                        value={this.props.sequenceNote}
                        onChange={(e) => {
                          this.handleMainNoteUpdate(e);
                        }}
                        onBlurCall={() => this.updateSequenceItemsNote()}
                        force_save_outside={force_note_save}
                        resetForceToSaveNote={() => {
                          this.resetForceToSaveNote();
                        }}
                      />
                    </div>
                  </TabContent>

                  <TabContent for="material">
                    <Material
                      ueId={false}
                      actions={sequenceMaterialSlice.actions}
                      thunks={sequenceMaterialThunks}
                      modelSelector={sequenceMaterialSelector}
                      isVisible={this.state.current_tab_settings === "material"}
                    />
                  </TabContent>
                  <TabContent for="schoolunites">
                    <Articulation
                      isVisible={
                        this.state.current_tab_settings === "schoolunites"
                      }
                    />
                  </TabContent>
                </div>
              </Tabs>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export const Component = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Sequences));
