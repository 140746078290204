'use strict';

exports.__esModule = true;
exports.defaultActiveStyle = undefined;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _class, _temp2;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var defaultActiveStyle = exports.defaultActiveStyle = {
  fontWeight: 'bold'
};

var TabLink = (_temp2 = _class = function (_Component) {
  _inherits(TabLink, _Component);

  function TabLink() {
    var _temp, _this, _ret;

    _classCallCheck(this, TabLink);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, _Component.call.apply(_Component, [this].concat(args))), _this), _this.handleClick = function (e) {
      _this.props.handleSelect(_this.props.to, _this.props.namespace);

      if (_this.props.onClick) {
        _this.props.onClick(e);
      }
    }, _this.handleKeyPress = function (e) {
      if (e.key === ' ' || e.key === 'Enter') {
        e.preventDefault();

        _this.handleClick(e);
      }
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  TabLink.prototype.render = function render() {
    var _classNames;

    var _props = this.props,
        to = _props.to,
        handleSelect = _props.handleSelect,
        isActive = _props.isActive,
        namespace = _props.namespace,
        activeStyle = _props.activeStyle,
        disableInlineStyles = _props.disableInlineStyles,
        className = _props.className,
        activeClassName = _props.activeClassName,
        style = _props.style,
        passedProps = _objectWithoutProperties(_props, ['to', 'handleSelect', 'isActive', 'namespace', 'activeStyle', 'disableInlineStyles', 'className', 'activeClassName', 'style']);

    var _className = className || 'tab-link';
    var _activeClassName = activeClassName || 'tab-link-active';
    var _style = _extends({}, style, isActive && (activeStyle || defaultActiveStyle) || {});
    var componentType = this.props.component || 'button';

    return _react2.default.createElement(componentType, _extends({
      id: 'tab-' + to,
      role: 'tab',
      'aria-selected': isActive ? 'true' : 'false',
      'aria-controls': 'tabpanel-' + to,
      className: (0, _classnames2.default)((_classNames = {}, _classNames[_className] = true, _classNames[_activeClassName] = isActive, _classNames)),
      style: !disableInlineStyles && _style || undefined
    }, passedProps, {
      onKeyPress: this.handleKeyPress,
      onClick: this.handleClick
    }), this.props.children);
  };

  return TabLink;
}(_react.Component), _class.displayName = 'TabLink', _temp2);


TabLink.propTypes = process.env.NODE_ENV !== "production" ? {
  to: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.number]).isRequired,
  component: _propTypes2.default.string,
  handleSelect: _propTypes2.default.func,
  onClick: _propTypes2.default.func,
  children: _propTypes2.default.node,
  isActive: _propTypes2.default.bool,
  namespace: _propTypes2.default.string,
  activeStyle: _propTypes2.default.object,
  disableInlineStyles: _propTypes2.default.bool,
  className: _propTypes2.default.string,
  activeClassName: _propTypes2.default.string,
  style: _propTypes2.default.object
} : {};

exports.default = TabLink;