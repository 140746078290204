import React, { useState } from "react";

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function ImageWithDefaultBlock(props: {
  placeholderTxt: string;
  src: string;
  className: string;
}) {
  const [imgHasError, setImgHasError] = useState(false);
  const onError = () => {
    setImgHasError(true);
  };
  if (imgHasError) {
    return (
      <div className="defaultNoImage error">
        <span className="text">{props.placeholderTxt}</span>
      </div>
    );
  }
  return <img src={props.src} className={props.className} onError={onError} />;
}
